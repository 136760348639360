<template>
    <b-modal
        id="modal-delete-reason"
        shadow
        bg-variant="white"
        backdrop
        hide-footer
        centered
    >
        <template #modal-header>
            <div class="d-flex align-items-center justify-content-start w-100 title-modal">
                <span class="modal-title">
                    {{$t('RefuseStatus.Attention')}}
                </span>
            </div>
            <button 
                type="button" 
                aria-label="Close" 
                class="close" 
                @click="closeModal"
            >×</button>
        </template>

        <b-form class="pt-2">
            <b-row class="col-12 m-0 container-fields-reason">
                <b-col class="12">
                    <b-form-group
                    >
                        <span>
                            {{ $t('RefuseStatus.TextAttention') }}
                        </span>
                    </b-form-group>
                </b-col>

                <b-col md="12" class="d-flex justify-content-center justify-content-sm-end">
                    <b-form-group>
                        <b-button v-if="!sendingForm"
                            class="btn-cancel-reason"
                            @click="closeModal"
                        >
                            {{ $t('RefuseStatus.Cancel') }}
                        </b-button>
                        <b-button v-if="!sendingForm"
                            class="btn-save-reason"
                            @click="deleteStatus(refuseStatusId)"
                        >
                            {{ $t('RefuseStatus.Delete') }}
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-modal>
</template>

<script>
    import { 
        BModal,
        BForm,
        BButton,
        BCol,
        BRow,
        BFormInput,
        BFormGroup,
        BFormTextarea
    } from 'bootstrap-vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            BModal,
            BForm,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BButton,
            BFormTextarea,
            ToastificationContent
            
        },
        props:{
            refuseStatusId: {
                type: String,
            },
            showToast: {
                type: Function,
            },
            allowEdit: {
                type: Boolean
            }
        },
        data() {
            return {
            currentSite: localStorage.getItem('currentSite'),
            sendingForm: false
            }
        },
        mounted(){
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                if(modalId == 'modal-delete-reason'){
                    this.sendingForm = false;         
                }
            })
        },
        methods: {
            closeModal(){
                this.$bvModal.hide('modal-delete-reason');
            },
            deleteStatus(id){
                if(this.allowEdit){
                    this.$http.delete(`/api/${this.$router.currentRoute.params.prefix}/refuse-status/${id}`)
                        .then(() => {
                            this.$emit('refetch-data');
                            this.showToast(this.$t('RefuseStatus.Success'), 'SmileIcon', this.$t('RefuseStatus.MessageDeleteSuccess'), 'success');
                            
                        })
                        .catch((error) => {
                            this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', error.response.data.message, 'danger');
                        })
                } else {
                    this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('RefuseStatus.MessageDeleteError'), 'danger');
                }
                
                this.closeModal();
            }
        }
    }

</script>

<style lang="scss">

@media (min-width:768px) {
  #modal-delete-reason {
    padding: 0 !important;

    .modal-content,
    .modal-dialog {
      max-width: 746px !important;
    }
  }
}

@media (max-width:480px) {
    #modal-insert-pin {
        padding: 0 !important;
        .modal-content,
        .modal-dialog {
            max-width: 350px !important;
        }

        .modal-dialog {
            margin: 0 auto;
        }

        .modal-header {
            height: 106px !important;
            padding-bottom: 0;
            .title-modal {
                span {
                    font-size: 12px !important;
                }
            }
        }
    }
}

#modal-delete-reason{
    .font-style-mip {
        line-height: 20px;
        font-weight: 400;
    }

    .font-weight-600 {
        font-weight: 600;
    }

    .modal-dialog {
        width: 95%;
        max-width: none;
    }

    .modal-content {
        width: 100%;
        right: 0 !important;
    }

    .modal-header {
        position: relative !important;
        height: 66px;
        background-color: #D32F2F;
        .title-modal{
            height: 100%;
        }
        .modal-title{
            line-height: 26px;
            font-weight: 600;
            font-size: 18px;
            color: #fff;
        }
    }

    .modal-body {
        padding: 0 !important;
        .btn-cancel-reason {
            background-color: #fff !important;
            border: none;
            color: #974900 !important; 
            margin-right: 10px;
            transition: .7s;
            &:hover {
                background-color: #FFDBC4 !important;
                box-shadow: none !important;
            }
        }
        .btn-save-reason {
            background: #974900 !important;
            border: none;

            &:hover {
                box-shadow: none !important;
            }
        }

        .container-fields-reason {
            legend {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #4C4541;
            }
        }

    }
}
</style>